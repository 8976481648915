import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      Willbur, J. F., M. L. Fall, A. M. Byrne, S. A. Chapman, M. M. McCaghey, B. D. Mueller, R. Schmidt, M. I. Chilvers, D. S. Mueller, M. Kabbage, L. J. Giesler, S. P. Conley, and D. L. Smith. 2018. Validating Sclerotinia sclerotiorum Apothecial Models to Predict Sclerotinia Stem Rot in Soybean (Glycine max) Fields. Plant Disease. 102: 2592–2601. <a href="https://doi.org/10.1094/PDIS-02-18-0245-RE" target="blank" rel="noreferrer">https://doi.org/10.1094/PDIS-02-18-0245-RE</a>
      </p>
    </>
  )
}
