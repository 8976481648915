import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import Dropdown from "./_dropdown";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./white-mold-in-beans.json";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

// SET DEFAULT VISIBILITY OF TOGGLE ELEMENTS IN LEFT SIDEBAR \ Alex Sinfarosa 20201231
const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

// GENERATE MODEL DISPLAY OUTPUT AND DATA ELEMENTS | Alex Sinfarosa 20201231
export default function WhiteMoldInBeans() {
  const h1Text = modelData.title;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  // CONFIGURE A LIST OF ROW SPACING OPTIONS | Dan Olmstead 20221230
  const ROW_SPACING_OPTIONS = [
    { value: "inches15", label: "15-inch" },
    { value: "inches30", label: "30-inch" },
  ];
  const [rowSpacingStatus, setRowSpacingStatus] = React.useState(
    ROW_SPACING_OPTIONS[0]
  );
  /// Convert row spacing selection to boolean for risk analysis | Dan Olmstead 20221230
  let ROW_SPACING = 0;
  if (rowSpacingStatus.value === "inches15") {
    ROW_SPACING = 0;
  } else if (rowSpacingStatus.value === "inches30") {
    ROW_SPACING = 1;
  }

  // CONFIGURE A LIST OF FLOWERING STATUS OPTIONS | Dan Olmstead 20221230
  const FLOWERING_OPTIONS = [
    { value: "flowering", label: "flowering" },
    { value: "notFlowering", label: "not flowering" },
  ];
  const [floweringStatus, setFloweringStatus] = React.useState(
    FLOWERING_OPTIONS[0]
  );

  // CONFIGURE A LIST OF FLOWERING STATUS OPTIONS | Dan Olmstead 20221230
  const IRRIGATION_OPTIONS = [
    { value: "irrigated", label: "irrigated" },
    { value: "notIrrigated", label: "not irrigated" },
  ];
  const [irrigationStatus, setIrrigationStatus] = React.useState(
    IRRIGATION_OPTIONS[0]
  );

  // GATHER STATION METADATA AND WEATHER DATA | Alex Sinfarosa 20201231
  const { sdate, edate } = sdateEdate(dateOfInterest, modelData.seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);
  let mData = null;
  if (data && station) {
    mData = modelLogic(modelData, data, dateOfInterest);

    // FAHRENHEIT TO CELSIUS FUNCTION | Dan Olmstead 20221226
    function F_to_C(a) {
      /// A standard formula for F to C conversion | Dan Olmstead 20221230
      return (a - 32) * (5 / 9);
    }

    // MPH TO KPH FUNCTION | DAN OLMSTEAD 20221228
    function MPH_TO_KPH(a) {
      let KILOMETERS_IN_A_MILE = 1.60934;
      return a * KILOMETERS_IN_A_MILE;
    }

    // ADD CUSTOM CALCULATIONS TO DATA ARRAY FOR WHITE MOLD CALCULATIONS | Dan Olmstead 20221230
    for (let i = 0; i < mData.data.length; i++) {
      /// Tabulate daily maximum temperature in Celsius | Dan Olmstead 20221224
      mData.data[i]["maxt_C"] = Math.round(F_to_C(mData.data[i]["maxt"]));

      /// Tabulate daily maximum relative humidity value | Dan Olmstead 20221230
      mData.data[i]["maxrhum_Pct"] = Math.max(...mData.data[i]["rhum"]);

      /// Find maximum daily windspeed and convert to kph | Dan Olmstead 20221230
      mData.data[i]["maxwspd_kph"] = Math.round(
        MPH_TO_KPH(Math.max(...mData.data[i]["wspd"]))
      );

      /// Tabulate the index value 30 days prior to record of interest for moving average calculations | Dan Olmstead 20221230
      const iSTART = i - 29;
      /// Tabulate the index value immediately following the record of interest for moving average calculations | Dan Olmstead 20221230
      const iEND = i + 1;
      /// Save the prior 30 days of maximum temp C, RH %, and windspeed kph | Dan Olmstead 20221230
      mData.data[i]["maxValues30daySubset"] = Object.values(
        mData.data.slice([iSTART], [iEND])
      ).map((item) => {
        return {
          maxt_C: item.maxt_C,
          maxrhum_Pct: item.maxrhum_Pct,
          maxwspd_kph: item.maxwspd_kph,
        };
      });
      /// Save the prior 30 days subset as a separate array for analysis | Dan Olmstead 20221230
      let DATA_30d = mData.data[i]["maxValues30daySubset"];

      /// Tabulate the sum of maximum temperature values over the past 30 days | Dan Olmstead 20221230
      let MAX_T_C_SUM = 0;
      for (let i = 0; i < DATA_30d.length; i++) {
        MAX_T_C_SUM = MAX_T_C_SUM + DATA_30d[i]["maxt_C"];
      }
      /// Calculate the 30-day maximum temperature moving average | Dan Olmstead 20221230
      mData.data[i]["maxt_C_30d_moving_average"] = MAX_T_C_SUM / 30;

      /// Tabulate the sum of maximum relative humidity values over the past 30 days | Dan Olmstead 20221230
      let MAX_RHUM_PCT_SUM = 0;
      for (let i = 0; i < DATA_30d.length; i++) {
        MAX_RHUM_PCT_SUM = MAX_RHUM_PCT_SUM + DATA_30d[i]["maxrhum_Pct"];
      }
      /// Calculate the 30-day maximum relative humidity moving average | Dan Olmstead 20221230
      mData.data[i]["maxrhum_Pct_30d_moving_average"] = MAX_RHUM_PCT_SUM / 30;

      /// Tabulate the sum of maximum relative humidity values over the past 30 days | Dan Olmstead 20221230
      let MAX_WSPD_KPH_SUM = 0;
      for (let i = 0; i < DATA_30d.length; i++) {
        MAX_WSPD_KPH_SUM = MAX_WSPD_KPH_SUM + DATA_30d[i]["maxwspd_kph"];
      }
      /// Calculate the 30-day maximum windspeed moving average | Dan Olmstead 20221230
      mData.data[i]["maxwspd_kph_30d_moving_average"] = MAX_WSPD_KPH_SUM / 30;

      /// Calculate the non-irrigated logit value from Willbur et al (2018) | Dan Olmstead 20221230
      mData.data[i]["non_irrigated_logit_darksky"] =
        -0.47 * (mData.data[i]["maxt_C_30d_moving_average"] + 0.865) -
        1.01 * (mData.data[i]["maxwspd_kph_30d_moving_average"] + 0.855) +
        16.65;

      /// Calculate the non-irrigated infection probability from Willbur et al (2018) | Dan Olmstead 20221230
      mData.data[i]["non_irrigated_probability"] =
        Math.exp(mData.data[i]["non_irrigated_logit_darksky"]) /
        (1 + Math.exp(mData.data[i]["non_irrigated_logit_darksky"]));

      /// Calculate non-irrigated infection risk percent | Dan Olmstead 20221230
      if (mData.data[i]["dayOfYear"] < 30) {
        mData.data[i]["non_irrigated_risk_percent"] = 0;
      } else {
        mData.data[i]["non_irrigated_risk_percent"] = Math.floor(
          mData.data[i]["non_irrigated_probability"] * 100
        );
      }

      /// Calculate the irrigated logit value from Willbur et al (2018) | Dan Olmstead 20221230
      mData.data[i]["irrigated_logit_darksky"] =
        -2.38 * ROW_SPACING +
        0.65 * (mData.data[i]["maxt_C_30d_moving_average"] + 0.865) +
        0.38 * (mData.data[i]["maxrhum_Pct_30d_moving_average"] + 4.539) -
        52.65;

      /// Calculate irrigated infection probability from Willbur et al (2018) | Dan Olmstead 20221230
      if (mData.data[i]["dayOfYear"] < 30) {
        mData.data[i]["irrigated_probability"] = 0;
      } else {
        mData.data[i]["irrigated_probability"] =
          Math.exp(mData.data[i]["irrigated_logit_darksky"]) /
          (1 + Math.exp(mData.data[i]["irrigated_logit_darksky"]));
      }

      /// Calculate irrigated infection risk percent | Dan Olmstead 20221230
      if (mData.data[i]["dayOfYear"] < 30) {
        mData.data[i]["irrigated_risk_percent"] = 0;
      } else {
        mData.data[i]["irrigated_risk_percent"] = Math.floor(
          mData.data[i]["irrigated_probability"] * 100
        );
      }
    }

    // DETERMINE THE START AND END INDEX SLICE VALUES FOR DAY OF INTEREST RECORD | Dan Olmstead 20221230
    var DATE_OF_INTEREST_INDEX = dateOfInterest.dayOfYear - 1;
    var DATE_OF_INTEREST_SLICE_END = DATE_OF_INTEREST_INDEX + 1;
    if (DATE_OF_INTEREST_INDEX >= 363) {
      DATE_OF_INTEREST_INDEX = 363;
      DATE_OF_INTEREST_SLICE_END = 364;
    } else {
      DATE_OF_INTEREST_SLICE_END = DATE_OF_INTEREST_INDEX + 1;
    }

    // GET RISK PERCENT VALUES FOR IRRIGATED AND NON IRRIGATED FROM DATE OF INTEREST RECORD | Dan Olmstead 20221230
    let DATE_OF_INTEREST_DATA = mData.data.slice(
      DATE_OF_INTEREST_INDEX,
      DATE_OF_INTEREST_SLICE_END
    );
    let RISK_PERCENT_NON_IRRIGATED =
      DATE_OF_INTEREST_DATA[0]["non_irrigated_risk_percent"];
    let RISK_PERCENT_IRRIGATED =
      DATE_OF_INTEREST_DATA[0]["irrigated_risk_percent"];

    // DETERMINE FINAL RISK PERCENT USING FLOWERING AND IRRIGATION STATUS | Dan Olmstead 20221230
    if (
      floweringStatus.value === "flowering" &&
      irrigationStatus.value === "notIrrigated"
    ) {
      var RISK_PERCENT_FINAL = RISK_PERCENT_NON_IRRIGATED;
    } else if (
      floweringStatus.value === "flowering" &&
      irrigationStatus.value === "irrigated"
    ) {
      RISK_PERCENT_FINAL = RISK_PERCENT_IRRIGATED;
    } else {
      RISK_PERCENT_FINAL = 0;
    }
  }

  // Lorem ipsum | Alex Sinfarosa 20201231
  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const isVisible = station && mData;

  // HTML OUTPUT | Dan Olmstead 20221230
  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      tutorialLink={modelData.tutorialLink}
      dateOfInterest={dateOfInterest}
      h1Text={h1Text}
    >
      <small>
        Based on white mold research in soybean by{" "}
        <a
          href="https://doi.org/10.1094/PDIS-02-18-0245-RE"
          target="blank"
          rel="noreferrer"
        >
          Willbur et al (2018)
        </a>{" "}
        at{" "}
        <a
          href="https://plantpath.wisc.edu/faculty/damon-smith-2/"
          target="blank"
          rel="noreferrer"
        >
          University of Wisconsin-Madison
        </a>
        .
      </small>
      <br></br>
      <br></br>

      {/* White Mold Risk Summary */}
      <div className="mb-16 sm:mb-18 md:mb-20">
        <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
          White Mold Risk Summary
        </h2>
        <div className="px-6 py-6 bg-white rounded-lg shadow-lg sm:text-left">
          <h2>{dateOfInterest.server}</h2>
          <p>
            <b>IMPORTANT NOTICE. </b>
            <i>Spring 2023 - </i>the new white mold for soybean model requires
            validation work for accuracy and precision under New York State
            growing conditions.{" "}
            <b>
              <i>PROCEED WITH CAUTION.</i>
            </b>{" "}
            It is very important to verify model output by scouting your fields
            before taking costly management actions. Check back often for
            updates.
          </p>
          <p>
            <mark className="highlight">
              Chance of white mold infection is <b>{RISK_PERCENT_FINAL}%</b>.
            </mark>{" "}
            This assumes the field is planted at{" "}
            <u>
              <i>{rowSpacingStatus.label}</i>
            </u>{" "}
            row spacing, is{" "}
            <u>
              <i>{irrigationStatus.label}</i>
            </u>
            , and beans are{" "}
            <u>
              <i>{floweringStatus.label}</i>
            </u>
            . Update crop details below if any of these factors are incorrect.
          </p>
        </div>
      </div>

      {/* User Input */}
      <div className="mb-16 sm:mb-18 md:mb-20">
        <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
          Crop Details
        </h2>
        <div className="px-6 py-6 bg-white rounded-lg shadow-lg sm:text-left">
          <div className="grid max-w-2xl grid-cols-1 gap-4 py-4 mx-auto lg:grid-cols-3">
            <div>
              Row spacing
              <div>
                <Dropdown
                  width="w-40"
                  id="row-spacing-status"
                  label="row spacing status"
                  placeholder="Row spacing status"
                  ariaLabel="Row spacing status"
                  list={ROW_SPACING_OPTIONS}
                  item={rowSpacingStatus}
                  setUserItem={setRowSpacingStatus}
                ></Dropdown>
              </div>
            </div>
            <div>
              Irrigation status
              <div>
                <Dropdown
                  width="w-40"
                  id="irrigation-status"
                  label="Irrigation status"
                  placeholder="Irrigation status"
                  ariaLabel="Irrigation status"
                  list={IRRIGATION_OPTIONS}
                  item={irrigationStatus}
                  setUserItem={setIrrigationStatus}
                ></Dropdown>
              </div>
            </div>
            <div>
              Flowering status
              <div>
                <Dropdown
                  width="w-40"
                  id="flowering-status"
                  label="Flowering status"
                  placeholder="Flowering status"
                  ariaLabel="Flowering status"
                  list={FLOWERING_OPTIONS}
                  item={floweringStatus}
                  setUserItem={setFloweringStatus}
                ></Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable station={station} data={mData.data}></ResultsTable>
        </div>
      )}
    </ModelLayout>
  );
}
