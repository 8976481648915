import React from "react"

export default function Acknowledgments() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The NEWA white mold in beans model is based on work published by Willbur et al (2018). This web app is built, maintained, and updated by New York State Integrated Pest Management, part of Cornell Cooperative Extension in the College of Agriculture and Life Sciences at Cornell University.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Willbur, J. F., M. L. Fall, A. M. Byrne, S. A. Chapman, M. M. McCaghey, B. D. Mueller, R. Schmidt, M. I. Chilvers, D. S. Mueller, M. Kabbage, L. J. Giesler, S. P. Conley, and D. L. Smith. 2018. Validating Sclerotinia sclerotiorum Apothecial Models to Predict Sclerotinia Stem Rot in Soybean (Glycine max) Fields. Plant Disease. 102: 2592–2601. <a href="https://doi.org/10.1094/PDIS-02-18-0245-RE" target="blank" rel="noreferrer">https://doi.org/10.1094/PDIS-02-18-0245-RE</a>
      </p>
    </>
  )
}
