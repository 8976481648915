import GlobalStateContext from "../../context/globalStateContext"
import modelData from "./white-mold-in-beans.json"
import { format } from "date-fns"
import React from "react"
import { CSVLink } from "react-csv"

export default function ResultsTable({ station, data }) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)
  const { title } = modelData.elements.resultsTable

  let tableData = [...data]
  if (data.length >= 8) {
    tableData = data.slice(
      dateOfInterest.dayOfYear - 3,
      dateOfInterest.dayOfYear + 5
    )
  }
  console.log("TABLE DATA:",tableData)

  let csvData = data.map((a) => {
    return {
      date: a.dateDisplay,
      maxtFDaily: a.maxt,
      maxtCDaily: a.maxt_C,
      maxtC30dayMovingAve: a.maxt_C_30d_moving_average,
      maxRhPercent30dayMovingAve: a.maxrhum_Pct_30d_moving_average,
      maxWspdKph30dayMovingAve: a.maxwspd_kph_30d_moving_average,
      nonIrrigatedRiskProbability: a.non_irrigated_probability,
      irrigatedRiskProbability: a.irrigated_probability
    }
  })

  if (!tableData) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`${station.name}_${station.state}_western_bean_cutworm_flights.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{" "}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800"
                    rowSpan="2"
                  >
                    Date <small>({dateOfInterest.year})</small>
                  </th>
                  <th
                  className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-b border-r border-gray-200 bg-secondary-800"
                  colSpan="3"
                  >
                    30-day Maximum Moving Averages
                  </th>
                  <th
                  className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-b border-r border-gray-200 bg-secondary-800"
                  colSpan="2"
                  >
                    % Chance of White Mold if Plants are Flowering (R1-R4)
                  </th>
                </tr>
                <tr className="text-center">
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-b border-r border-gray-200 bg-secondary-800">
                    &#176;C
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-b border-r border-gray-200 bg-secondary-800">
                    RH%
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-b border-r border-gray-200 bg-secondary-800">
                    Wind speed (kph)
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-b border-r border-gray-200 bg-secondary-800">
                    Not irrigated
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-b border-r border-gray-200 bg-secondary-800">
                    Irrigated
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((day, i) => {
                  return (
                    <tr
                      key={day.ms}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td
                        className={`text-xs sm:text-sm px-6 h-4 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        <span className="flex flex-col items-center">
                          {format(day.date, "LLL d")}
                          <small className="mt-1 font-bold text-gray-500">
                            {day.isForecast ? "Forecast" : ""}
                          </small>
                        </span>
                      </td>
                      <td className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}>
                        {Math.round(day.maxt_C_30d_moving_average)}                        
                      </td>
                      <td className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}>
                        {Math.round(day.maxrhum_Pct_30d_moving_average)}                        
                      </td>
                      <td className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}>
                        {Math.round(day.maxwspd_kph_30d_moving_average)}                        
                      </td>
                      <td className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}>
                        {day.non_irrigated_risk_percent}                        
                      </td>
                      <td className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}>
                        {day.irrigated_risk_percent}                        
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
