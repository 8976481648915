import React from "react"

export default function MoreInfo({ moreInfo }) {
  return (
    <div>
      <p className="text-sm leading-5 text-left text-gray-500">The NEWA white mold in beans model is based on research and validation studies completed by University of Wisconsin at Madison for soybean production in the midwest United States. White mold stem rot occurs when ascospores, ejected from the fruiting bodies of <i>Sclerotinia sclerotiorum</i> called apothecia, land on soybean or snap bean flowers and grow into the plant. The apothecia form from hard black fungal nodules on the ground called sclerotia. The white mold in beans model uses 30-day averages of temperature and windspeed, combined with user-provided information on canopy closure, irrigation status, and flowering status.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">Based on researched described in Willbur, J. F., M. L. Fall, A. M. Byrne, S. A. Chapman, M. M. McCaghey, B. D. Mueller, R. Schmidt, M. I. Chilvers, D. S. Mueller, M. Kabbage, L. J. Giesler, S. P. Conley, and D. L. Smith. 2018. Validating Sclerotinia sclerotiorum Apothecial Models to Predict Sclerotinia Stem Rot in Soybean (Glycine max) Fields. Plant Disease. 102: 2592–2601. <a href="https://doi.org/10.1094/PDIS-02-18-0245-RE" target="blank" rel="noreferrer">https://doi.org/10.1094/PDIS-02-18-0245-RE</a></p>
    </div>
  )
}
